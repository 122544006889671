import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ButtonBack} from "../components/Buttons";
import * as actions from "../store/actions";
import LoadingSpinner from "../assets/svg/LoadingSpinner";
import {tripStatusLabel} from "../functions/enum";
import ListBoxBookingHistoryFilter from "../components/ListBoxBookingHistoryFilter";
import {useHistory} from "react-router";
import {updateCurrentUrlParams} from "../functions/url";
import ListBoxBookingHistoryMonth from "../components/ListBoxBookingHistoryMonth";
import {formatDate} from "../functions/dateHelper";
import {formatEur} from "../functions/unitFormater";

const CustomerBookingHistory = () => {
  const dispatch = useDispatch()

  const history = useHistory()

  const bookingHistory = useSelector(state => state.customer.bookingHistoryFiltered) || {}
  const bookingHistoryLoading = useSelector(state => state.customer.bookingHistoryLoading)
  const bookingHistoryFilterAttributes = useSelector(state => state.customer.bookingHistoryFilterAttributes)
  const bookingHistoryFilterSelected = useSelector(state => state.customer.bookingHistoryFilterSelected) || {}

  useEffect(() => {
    dispatch(actions.getBookingHistoryAttributes())
    dispatch(actions.getBookingHistory())
  }, [dispatch])

  const updateMonth = (e) => {
    updateCurrentUrlParams(history, {month: e.id})
    dispatch(actions.updateBookingHistoryFilter({month: e}))
  }

  const updateHotel = (e) => {
    const queryList = e?.map(hotel => hotel.id)?.join('|') || ''
    updateCurrentUrlParams(history, {hotel: queryList})
    dispatch(actions.updateBookingHistoryFilter({hotels: e}))
  }

  const updateGuest = (e) => {
    const queryList = e?.map(guest => guest.id)?.join('|') || ''
    updateCurrentUrlParams(history, {guest: queryList})
    dispatch(actions.updateBookingHistoryFilter({guests: e}))
  }

  const updateStatus = (e) => {
    const queryList = e?.map(status => status.id)?.join('|') || ''
    updateCurrentUrlParams(history, {status: queryList})
    dispatch(actions.updateBookingHistoryFilter({status: e}))
  }


  return (
    <div className="container max-w-screen-lg mx-auto">

      <div className="mt-8 mb-16 mx-4">

        <ButtonBack />

        <div className="text-center text-3xl font-light mt-8">Buchungsübersicht</div>
        <div className="text-center text-md font-light mt-2">Alle gebuchten Nächte auf einen Blick.</div>


        {bookingHistoryLoading
          ? <div className="flex justify-center mt-4"><LoadingSpinner /></div>
          : bookingHistoryFilterAttributes?.uniqueMonthList?.length > 0
            ? <>

              <div className="my-4 p-4 flex flex-col">
                <div className="font-light uppercase tracking-wider text-center mt-2">Monat</div>
                <div className="flex justify-evenly flex-wrap">
                  <ListBoxBookingHistoryMonth
                    data={bookingHistoryFilterAttributes.uniqueMonthList}
                    item={bookingHistoryFilterSelected.month}
                    setItem={updateMonth}
                  />
                </div>

                <div className="font-light uppercase tracking-wider text-center mt-6">Filter</div>
                <div className="font-light text-center text-xs">Mehrfachauswahl möglich</div>
                <div className="rounded-xl mx-auto p-2">
                  <div className="flex justify-evenly flex-wrap">
                    <div className="flex flex-col">
                      <ListBoxBookingHistoryFilter
                        data={bookingHistoryFilterAttributes.uniqueGuestList}
                        item={bookingHistoryFilterSelected.guests}
                        setItem={updateGuest}
                        lable={'Gäste'}
                        multiple={true}
                      />
                    </div>
                    <div className="flex flex-col">
                      <ListBoxBookingHistoryFilter
                        data={bookingHistoryFilterAttributes.uniqueHotelList}
                        item={bookingHistoryFilterSelected.hotels}
                        setItem={updateHotel}
                        lable={'Hotels'}
                        multiple={true}
                      />
                    </div>
                    <div className="flex flex-col">
                      <ListBoxBookingHistoryFilter
                        data={bookingHistoryFilterAttributes.uniqueStatusList}
                        item={bookingHistoryFilterSelected.status}
                        setItem={updateStatus}
                        lable={'Status'}
                        multiple={true}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-center rounded-lg mt-2">

                <table className="table-auto text-sm md:text-base md:w-11/12">
                  <thead>
                    <tr className="text-gray-900 bg-gray-100">
                      <th className="p-1 sm:p-2">Datum</th>
                      <th className="p-1 sm:p-2">Nächte</th>
                      <th className="p-1 sm:p-2">Gast</th>
                      <th className="p-1 sm:p-2">Hotel</th>
                      <th className="p-1 sm:p-2">Buchungs ID<br/>Status</th>
                      <th className="p-1 sm:p-2">Kosten</th>
                      <th className="p-1 sm:p-2">Kostenstelle</th>
                    </tr>
                  </thead>
                  <tbody>

                    {bookingHistory.map(trip => {
                      const hotel = trip?.BookingSubscription?.BookingHotel?.name || '_'
                      const rebookHotel = trip?.RebookHotel?.name || ''
                      const assignHotel = trip?.AssignHotel?.name || ''
                      const guest = trip?.BusinessUserInvitation?.invitationID
                        ? trip?.BusinessUserInvitation?.firstName + ' ' + trip?.BusinessUserInvitation?.lastName
                        : trip?.BookingSubscription?.BookingCustomer?.firstName + ' ' + trip?.BookingSubscription?.BookingCustomer?.lastName
                      return(
                        <tr key={trip.tripID} className="border-b text-gray-700 w-64">
                          <td className="p-1 sm:p-2">
                            <div>{formatDate(trip.startDate)} - {formatDate(trip.departureDate)}</div>
                          </td>
                          <td className="p-1 text-center">
                            <div className="">{trip.nights}</div>
                          </td>
                          <td className="p-1 sm:p-2">{guest}</td>
                          <td className="p-1 sm:p-2 break-words w-36">
                            {(!assignHotel && !rebookHotel) ? <div>{hotel}</div> : ''}
                            {(assignHotel && !rebookHotel) ? <div className="">
                              <span className="font-light">Hotel:</span> {assignHotel}
                            </div> : ''}
                            {rebookHotel ? <div className="">
                              <span className="font-light">Umbuchung:</span> {rebookHotel}
                            </div> : ''}

                          </td>
                          <td className="p-1 sm:p-2 text-xs">
                            <div>{trip?.tripID}</div>
                            <div>{tripStatusLabel[trip.status]}</div>
                          </td>
                          <td className="p-1 sm:p-2 text-xs md:text-base">
                            <div>{formatEur(trip.tripPrice)}€</div>
                          </td>
                          <td className="p-1 sm:p-2 text-xs md:text-base">
                            <div className="text-xs">{trip?.BusinessUserInvitation?.costCenter}</div>
                          </td>
                        </tr>
                      )
                    })}

                  </tbody>
                </table>

              </div>
              </>
            : <div>
                <div className="mt-8 flex justify-center">
                  <div className="p-6 border border-gray-200 rounded-lg shadow-md bg-yellow-50 mx-6 text-center">
                    Aktuell liegen noch keine Reservierungen vor.<br/>
                  </div>
                </div>
              </div>
        }

      </div>

    </div>
  )
}

export default CustomerBookingHistory
