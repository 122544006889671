import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ButtonBack} from "../components/Buttons";
import * as actions from "../store/actions";
import {formatDate, formatDateDayMonth} from "../functions/dateHelper";
import {DocumentTextIcon} from "@heroicons/react/24/outline";
import LoadingSpinner from "../assets/svg/LoadingSpinner";
import placeholder from "../assets/images/dashboard_invoice_placeholder.webp";

const CustomerInvoice = () => {
  const dispatch = useDispatch()

  const invoices = useSelector(state => state.customer.invoices)
  const invoicesLoading = useSelector(state => state.customer.invoicesLoading)
  const invoiceList = (
    invoices?.invoiceList?.length > 0
    && invoices?.invoiceList?.filter(x => x.relatedVoucherType === '')
  ) || []

  useEffect(() => {
    dispatch(actions.getCustomerInvoice())
  }, [dispatch])


  return (
    <div className="container max-w-screen-lg mx-auto">

      <div className="mt-8 mb-16 mx-4">

        <ButtonBack />

        <div className="text-center text-3xl font-light mt-8">Rechnungen</div>
        <div className="text-center text-md font-light mt-2">Alle Belege digital abrufen.</div>


        {invoicesLoading
          ? <div className="flex justify-center mt-4"><LoadingSpinner /></div>
          : invoiceList.length > 0
            ? <div className="flex justify-center rounded-lg mt-8">

                <table className="table-auto border-collapse border border-slate-400 text-sm">
                  <thead>
                  <tr className="tr-class text-secondaryBlue bg-gray-50">
                    <th className="th-class">Datum <div className="text-gray-400 text-xs">Abo-Monat</div></th>
                    <th className="th-class">Summe</th>
                    <th className="th-class">Betreff</th>
                    <th className="th-class">PDF</th>
                  </tr>
                  </thead>
                  <tbody>
                  {invoiceList.map(x =>
                    <tr key={x.voucherId}>
                      <td className="td-class">
                        <div>{x.voucherDate ? <>{formatDate(x.voucherDate)}</> : ''}</div>
                        <div className="text-gray-400 text-xs">{x.shippingDate ? formatDate(x.shippingDate) : ''}
                          {x.shippingEndDate ? <> - {formatDate(x.shippingEndDate)}</> : ''}</div>
                      </td>
                      <td className="td-class">{x.totalAmount.toFixed(2).replace('.',',')} €</td>
                      <td className="td-class">{x.lineItem}</td>
                      <td className="td-class">
                        <a className="underline text-secondaryBlue hover:text-primary flex"
                           href={'/api/customer/invoice/pdf/'+x.invoiceFileId} target="_blank" rel="noreferrer">
                          <DocumentTextIcon className="mr-1 h-6 w-6" aria-hidden="true" />
                          <span className="hidden md:inline">{x.voucherNumber}</span>
                        </a>
                      </td>
                    </tr>)}
                  </tbody>
                </table>

              </div>
            : <div>
                <div className="mt-8 flex justify-center">
                  <div className="p-6 border border-gray-200 rounded-lg shadow-md bg-yellow-50 mx-6 text-center">
                    Aktuell liegen noch keine Rechnungen vor.<br/>
                  </div>
                </div>
                <div className="mt-20 flex flex-col justify-center">
                  <div className="mx-auto font-bold text-gray-400">Beispielansicht Rechnungen</div>
                  <div className="mx-auto pt-4 border-t border-gray-300"><img src={placeholder} alt="" style={{maxWidth: 600}}/></div>
                </div>
              </div>
        }

      </div>

    </div>
  )
}

export default CustomerInvoice
